import renderHydrogen from "@shopify/hydrogen/entry-client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://8c5309fea74140bcaacd1337e7f0b98a@o4504907420139520.ingest.sentry.io/4504907467390976",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: import.meta.env.MODE,
});

const ClientWrapper = (props) => props.children;

export default renderHydrogen(ClientWrapper);
